<template>
    <div class="main-wrapper">
        <el-scrollbar class="main-wrapper-scrollbar">
            <div class="wrapper-list">
                <div class="top-title">
                  <span class="title">{{op_name}}</span>
                  <span class="title-1" style="opacity: 0">{{op_name}}</span>
                  <el-button class="backBtn" type="primary" @click="goBack">返回</el-button>
                </div>
                <img src="../../../assets/images/student/bottom-line.png" alt="" style="width: 100%;display: block">
                <div class="time-item">
                    <div class="time-item-list">
                        <div class="time-title-left">
                            <span class="comma" style="margin-left: 22px">,,,</span>
                            <div class="item">
                                <span class="score-title">本题分值：{{score}}分</span>
                                <span style="margin-left: 50px">考核时间{{assess_time}}分钟</span>
                            </div>
                            <span class="comma">,,,</span>
                        </div>
                    </div>
                </div>
                <!--S 任务目标-->
                <div class="mission-wrapper" v-if="taskGoal">
                    <div class="mission-title">
                        <span class="line"></span>
                        <span class="mission-title-text">任务目标</span>
                    </div>
                    <div class="task-item" v-for="(item,index) in taskGoal" :key="`taskGoal${index}`">
                        <div class="task-bg">
                            <img src="../../../assets/images/student/task-bg.png" alt="">
                            <p class="num-title"><em style="color: #FEDB2E">N0.</em>{{item.index}}</p>
                            <p class="title">任务{{item.index}}</p>
                        </div>
                        <div class="task-content">
                            <p class="text">{{item.target}}</p>
                        </div>
                    </div>
                </div>
                <!--E 任务目标-->
                <!--S 任务背景-->
                <div class="mission-wrapper" v-if="task_background">
                    <div class="mission-title">
                        <span class="line"></span>
                        <span class="mission-title-text">任务背景</span>
                    </div>
                  <div class="task-item">
                    <div class="task-content">
                      <p class="text" v-html="task_background"></p>
                    </div>
                  </div>
                </div>
                <!--E 任务背景-->
                <!--S 任务分析-->
                <div class="mission-wrapper" v-if="task_analysis">
                    <div class="mission-title">
                        <span class="line"></span>
                        <span class="mission-title-text">任务分析</span>
                    </div>
                  <div class="task-item">
                    <div class="task-content">
                      <p class="text" v-html="task_analysis"></p>
                    </div>
                  </div>
                </div>
                <!--E 任务分析-->
                <!--S 任务操作-->
                <div class="mission-wrapper" v-if="task_operation">
                    <div class="mission-title">
                        <span class="line"></span>
                        <span class="mission-title-text">任务操作</span>
                    </div>
                  <div class="task-item">
                    <div class="task-content">
                      <div class="content-table g-table text" v-html="task_operation"></div>
                    </div>
                  </div>
                </div>
<!--                &lt;!&ndash;E 任务操作&ndash;&gt;-->
<!--                &lt;!&ndash;S 评分标准&ndash;&gt;-->
                <div class="mission-wrapper" v-if="scoringCriteria">
                    <div class="mission-title">
                        <span class="line"></span>
                        <span class="mission-title-text">评分标准</span>
                    </div>
                    <div class="task-item" >
                        <div class="task-content">
                            <el-table :data="scoringCriteria" style="width: 100%; flex: 1" size="medium" border
                                      :header-cell-style="{fontWeight: 'normal', color: '#333', background: '#C4F2E4',fontSize: '16px',height:'60px'}"
                                      :cell-style="{fontSize: '12px',color: '#333',height: '70px'}">
                                <el-table-column prop="index" label="序号" align="center" width="80px"></el-table-column>
                                <el-table-column prop="content" label="考核内容" align="center"></el-table-column>
                                <el-table-column prop="require" label="考核要点" align="center"></el-table-column>
                                <el-table-column prop="score" label="配分" align="center"></el-table-column>
                                <el-table-column prop="standard" label="评分标准" align="center"></el-table-column>
                            </el-table>
                        </div>
                    </div>
                </div>
                <!--E 评分标准-->
            </div>
        </el-scrollbar>
    </div>
</template>
<script>

    import {stuOpGetExamInfo} from '@/utils/apis'

    export default {
        name: "LookDetail",
        data() {
            return {
                type: '',
                op_name: '',//网店管理名称
                score: '',//分数
                assess_time: '',//时间
                task_background: '',//任务背景
                taskGoal:[
                    {
                        index:1,
                        target:'根据商品的信息，提炼商品的基本属性'
                    }
                ],
                scoringCriteria:[
                    {
                        index:1,
                        content:'商品信息表',
                        require:'商品基本参数信息正确',
                        score:1.5,
                        standard:'根据商品正确填写商品信息表，吊牌价格合理，设计风格符合商品',
                    },
                    {
                        index:2,
                        content:'商品使用说明',
                        require:'找词根',
                        score:2,
                        standard:'正确填写商品使用注意事项与步骤',
                    }
                ],
                task_analysis:[],
                task_operation:[
                    {
                        name:'淘宝装修',
                        pinpai:'lv',
                        gx:'规格',
                    },
                    {
                        name:'电商大赛',
                        pinpai:'coco',
                        gx:'规格',
                    }
                ],
                task_operation1:[
                    {
                        text:'商品基本信息及属性',
                        content:'商品特性、特点',
                    },
                    {
                        text:'商品的竞争优势及对比性',
                        content:'口碑、承诺与保障',
                    }
                ],
                examModuleId:'',
                // orderId:'',
                practiceTitle:'',
                practiceData: [],
            }
        },
        mounted() {
            this.getExamStuExamPage();
            // this.practiceData = JSON.parse(localStorage.getItem('projectsList'));
            // let id = this.$route.query.id;
            // this.practiceData.forEach(item =>{
            //   if(item.id == id){
            //     this.practiceTitle = item.name
            //   }
            // })
        },
      watch:{
        $route(route){
          if(route.query.id){
            let id = route.query.id;
            this.practiceData.forEach(item =>{
              if(item.id == id){
                this.practiceTitle = item.name
              }
            })
          }
        }
      },
        methods: {
          goBack() {
            this.$router.go(-1);
            // this.$router.push('/teachingCenter/index');
            // this.$emit('changeShow', 1)
          },
            getExamStuExamPage() {
                let params = {
                    type: 'shop_management'
                };
                if (this.$route.query.id) {
                    params.op_id = this.$route.query.id;
                }
                stuOpGetExamInfo(params).then((res) => {
                    this.op_name = res.data.op_name
                    this.score = res.data.score
                    this.assess_time = res.data.assess_time
                    this.taskGoal = res.data.task_goal
                    this.task_background = res.data.task_background
                    this.task_analysis = res.data.task_analysis
                    this.task_operation = res.data.task_operation
                    this.scoringCriteria = res.data.scoring_criteria
                    this.examModuleId = res.data.exam_module_id
                    // this.orderId = res.data.id
                    this.$message.success(res.msg);
                }).catch((err) => {
                    console.log(err);
                });
            },
        }
    }
</script>

<style scoped lang="scss">
::v-deep p{
  background: none!important;
}
    .main-wrapper {
        background: url("../../../assets/images/student/student-sct-bg.png") no-repeat;
        background-size: 100% 100%;
        height: 100%;
        position: relative;
        .main-wrapper-scrollbar {
            height: 100%;
            ::v-deep .el-scrollbar__wrap {
                overflow-x: hidden;
            }
            .wrapper-list {
                .top-title {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    font-size: 48px;
                    color: #35c773;
                    padding-top: 20px;
                    letter-spacing: 4px;
                    font-weight: bold;
                    position: relative;
                    .title {
                        position: absolute;
                        top: 0;
                    }
                    &:before {
                        content: url("../../../assets/images/student/line-left.png");
                        margin-right: 15px;
                    }
                    &:after {
                        content: url("../../../assets/images/student/line-right.png");
                        margin-left: 15px;
                    }
                  .backBtn {
                    position: absolute;
                    right: 40px;
                    top: 0;
                  }
                }
                .time-item {
                    /*position: absolute;*/
                    /*top: 180px;*/
                    margin-top: 80px;
                    width: 100%;
                    display: flex;
                    justify-content: center;

                    .time-item-list {
                        display: flex;
                        height: 80px;
                        line-height: 80px;
                        background: #39B678;

                        .time-title-left {
                            flex: 1;
                            display: flex;
                            border: 6px solid #61a885;
                            background: #DDFDF2;

                            .comma {
                                color: #31A588;
                                font-size: 66px;
                                letter-spacing: 18px;
                                text-align: center;
                                line-height: 33px;
                                display: inline-block;
                            }

                            .item {
                                flex: 1;
                                font-size: 24px;
                                padding: 0 40px;
                                color: #31A588;
                                position: relative;

                                &:after {
                                    content: '';
                                    height: 40px;
                                    width: 3px;
                                    background: #31A588;
                                    position: absolute;
                                    top: 14px;
                                    right: 10px;
                                }

                                &:before {
                                    content: '';
                                    height: 40px;
                                    width: 3px;
                                    background: #31A588;
                                    position: absolute;
                                    top: 14px;
                                    left: 10px;
                                }
                            }
                        }

                        .start-tex {
                            width: 120px;
                            font-size: 24px;
                            color: #fff;
                            cursor: pointer;
                            text-align: center;
                        }
                    }

                }
            }
            .mission-wrapper {
                width: 100%;
                .mission-title {
                    width: 100%;
                    height: 80px;
                    text-align: center;
                    font-size: 48px;
                    font-style: italic;
                    font-weight: 600;
                    position: relative;
                    margin-top: 40px;
                    .line {
                        position: absolute;
                        top: 40px;
                        left: calc(50% - 150px);
                        width: 300px;
                        height: 20px;
                        background: #FEDB2E;
                    }
                    .mission-title-text {
                        position: inherit;
                        /*left: calc(50% - 100px);*/
                        text-align: center;
                    }
                }

                .task-item {
                    margin-top: 20px;
                    .task-bg {
                        color: #fff;
                        position: relative;
                        margin-left: 50px;
                        .num-title {
                            font-size: 34px;
                            position: absolute;
                            top: -26px;
                            left: 50px;

                            em {
                                font-style: normal;
                            }
                        }
                        .title {
                            color: rgb(255, 255, 255);
                            font-size: 30px;
                            position: absolute;
                            top: -22px;
                            left: 126px;
                        }
                    }
                }
            }
        }


        .task-content {
            background: #DDFDF2;
            padding: 20px 20px 20px 50px;
            margin: -20px 20px;
            ::v-deep p img{
                max-width: 100%;
                max-height: 100%;
            }
            .text {
                ::v-deep p span {
                    background-color: transparent !important;
                }
                ::v-deep table {
                    tr {
                        height: 50px;
                        line-height: 50px;
                        td {
                            border: 1px solid #D2D2D2;
                            border-right: 2px solid #fff;
                            text-align: center;
                            font-size: 14px;
                            color: #333;
                            &:first-child {
                                border-right: 2px solid #fff;
                            }
                            &:last-child {
                                border-right:  1px solid #D2D2D2;
                            }
                        }
                        &:nth-child(2n) {
                            td {
                                background: #F2F9FA;
                            }
                        }
                        &:nth-child(2n-1) {
                            td {
                                background: #C4F2E4;
                            }
                        }
                    }
                }
            }
            ::v-deep .el-table--medium td, ::v-deep .el-table--medium th {
                border-right: 2px solid #fff;
            }
            ::v-deep .el-table tr {
                &:nth-child(even) {
                    td {
                        background:#C4F2E4;
                        border-bottom: 2px solid #fff
                    }
                }
                &:nth-child(odd) {
                    td {
                        background:#F2F9FA ;
                        border-bottom: 2px solid #fff
                    }
                }
            }
        }
    }
</style>